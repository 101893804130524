import mutations from "@/store/signupAndOnboarding/mutations";
import actions from "@/store/signupAndOnboarding/actions";
import getters from "@/store/signupAndOnboarding/getters";


const state ={
   signupAndOnboardingDetail:{}
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};